<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="设备编号">
                <a-input v-model="queryParam.code" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="设备类型">
                <a-select v-model="queryParam.deviceTypeId" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in typeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="附加信息">
                <a-input v-model="queryParam.appendInformation" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="客户名称">
                <a-input v-model="queryParam.customName" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm2.handleAdd()" v-hasPermi="['up:device:add:batch']">
          <a-icon type="plus" />批量新增
        </a-button>
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['up:device:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['up:device:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['up:device:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" :disabled="multiple" @click="onCheck()" v-hasPermi="['up:deviceupgrade:add:batch']">
          <a-icon type="import" />批量升级
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 批量操作 -->
      <create-form2
        ref="createForm2"
        @ok="getList"
      />
      <!-- 详情 -->
      <info
        ref="info"
        @ok="getList"
      />
      <!-- 升级 -->
      <version
        ref="version"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :scroll="{ x: 1500 }"
        :bordered="tableBordered">
        <span slot="status" slot-scope="text, record">
          <a-tag color="orange" v-if="record.status == 0">未激活</a-tag>
          <a-tag color="blue" v-if="record.status == 1">已激活</a-tag>
        </span>
        <span slot="onlineStatus" slot-scope="text, record">
          <a-tag color="red" v-if="record.onlineStatus == 0">离线</a-tag>
          <a-tag color="green" v-if="record.onlineStatus == 1">在线</a-tag>
          <a-tag color="orange" v-if="record.onlineStatus == 2">升级中</a-tag>
        </span>
        <!-- <span slot="appendInformation" slot-scope="text, record">
          <a-popover title="设备附加信息">
            <template slot="content">{{ record.appendInformation }}</template>
            <a type="primary">查看</a>
          </a-popover>
        </span> -->
        <span slot="activationTime" slot-scope="text, record">
          {{ parseTime(record.activationTime) }}
        </span>
        <span slot="lastOnlineTime" slot-scope="text, record">
          {{ parseTime(record.lastOnlineTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.version.getInfo(1,record.id,record.deviceTypeId)" v-hasPermi="['up:deviceupgrade:add']"><a-icon type="eye"/>升级</a>
          <a-divider type="vertical" v-hasPermi="['up:device:edit']"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['up:device:edit']"><a-icon type="edit" />修改</a>
          <a-divider type="vertical" v-hasPermi="['up:device:reset']"/>
          <a @click="handleReset(record)" v-hasPermi="['up:device:reset']"><a-icon type="eye"/>复位</a>
          <a-divider type="vertical" v-hasPermi="['up:device:remove']"/>
          <a @click="handleDelete(record)" v-hasPermi="['up:device:remove']"><a-icon type="delete" />删除</a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { devicePage, deviceDel, deviceReset, deviceTypeList, deviceUpgradeCheckBatch } from '@/api/pages/device'
import CreateForm from './modules/CreateForm'
import CreateForm2 from './modules/CreateForm2'
import Info from './modules/info'
import Version from './modules/version'
import { tableMixin } from '@/store/table-mixin'
import ImportExcel from './modules/ImportExcel'

export default {
  mixins: [tableMixin],
  name: 'Config',
  components: {
    CreateForm, ImportExcel, Info, CreateForm2, Version
  },
  dicts: ['sys_yes_no'],
  data () {
    return {
      typeList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      refreshing: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '设备编码',
          dataIndex: 'code',
          width: 160,
          fixed: 'left',
          align: 'center',
          ellipsis: true
        },
        {
          title: '设备类型',
          dataIndex: 'deviceTypeName',
          width: 120,
          fixed: 'left',
          align: 'center',
          ellipsis: true
        },
        {
          title: '客户名称',
          dataIndex: 'customName',
          width: 120,
          align: 'center',
          ellipsis: true
        },
        {
          title: '当前版本',
          dataIndex: 'currentVersion',
          width: 100,
          align: 'center',
          ellipsis: true
        },
        {
          title: '附加信息',
          dataIndex: 'appendInformation',
          // scopedSlots: { customRender: 'appendInformation' },
          width: 100,
          align: 'center',
          ellipsis: true
        },
        {
          title: '待升级版本',
          dataIndex: 'latestVersion',
          width: 100,
          align: 'center',
          ellipsis: true
        },
        {
          title: '鉴权码',
          dataIndex: 'authCode',
          width: 180,
          align: 'center',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 100,
          align: 'center',
          ellipsis: true
        },
        {
          title: '激活时间',
          dataIndex: 'activationTime',
          scopedSlots: { customRender: 'activationTime' },
          width: 150,
          align: 'center',
          ellipsis: true
        },
        // {
        //   title: '在线状态',
        //   dataIndex: 'onlineStatus',
        //   scopedSlots: { customRender: 'onlineStatus' },
        //   width: 100,
        //   align: 'center',
        //   ellipsis: true
        // },
        {
          title: '最后在线时间',
          dataIndex: 'lastOnlineTime',
          scopedSlots: { customRender: 'lastOnlineTime' },
          width: 150,
          align: 'center',
          ellipsis: true
        },
        {
          title: '操作',
          width: 260,
          fixed: 'right',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          ellipsis: true
        }
      ]
    }
  },
  filters: {
  },
  created () {
    deviceTypeList().then(response => {
      this.typeList = response.data
    })
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询参数列表 */
    getList () {
      this.onSelectChange([], [])
      this.loading = true
      // if (this.dateRange !== null && this.dateRange !== '' && this.dateRange.length === 2) {
      //   this.queryParam.createBeginTime = this.dateRange[0] + ' 00:00:00'
      //   this.queryParam.createEndTime = this.dateRange[1] + ' 23:59:59'
      // }
      devicePage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    onCheck() {
      var that = this
      deviceUpgradeCheckBatch({ deviceIds: that.ids }).then(response => {
          console.log(response.data)
          if (response.data.result === 0) {
            that.$message.warning(
              '请选择同类型设备',
              3
            )
          } else if (response.data.result === 1) {
            that.$refs.version.getInfo(2, that.ids, response.data.deviceTypeId)
          }
        }
      )
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const configIds = row.id ? [row.id] : this.ids
      this.$confirm({
        title: '提示',
        content: '确认删除所选中设备?',
        onOk () {
          return deviceDel(configIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 复位按钮操作 */
    handleReset (row) {
      var that = this
      this.$confirm({
        title: '提示',
        content: '确认复位所选中设备?',
        onOk () {
          return deviceReset({ id: row.id })
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '复位成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('up/up-device/export', {
            ...that.queryParam
          }, `设备_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <a-drawer
    width="800"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>详情</b>
    </a-divider>
    <a-descriptions bordered :column="2" size="middle">
      <a-descriptions-item label="设备类型">{{ form.typeName }}</a-descriptions-item>
      <a-descriptions-item label="iccid">{{ form.iccid }}</a-descriptions-item>
      <a-descriptions-item label="设备名称">{{ form.devName }}</a-descriptions-item>
      <a-descriptions-item label="设备状态">
        <a-tag color="green" v-if="form.devStatus == 0">正常</a-tag>
        <a-tag color="red" v-else-if="form.devStatus == 1">报警</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="设备编号">{{ form.devCode }}</a-descriptions-item>
      <a-descriptions-item label="水表编号">{{ form.devSn }}</a-descriptions-item>
      <a-descriptions-item label="口径">{{ form.caliber }}</a-descriptions-item>
      <a-descriptions-item label="IMEI">{{ form.imei }}</a-descriptions-item>
      <a-descriptions-item label="IMSI">{{ form.imsi }}</a-descriptions-item>
      <a-descriptions-item label="电池电压">{{ form.cellVoltage }}</a-descriptions-item>
      <a-descriptions-item label="信号强度">{{ form.signal }}</a-descriptions-item>
      <a-descriptions-item label="用水总量">{{ form.useWaterAll }}</a-descriptions-item>
      <!-- <a-descriptions-item label="水耗">{{ form.waterConsumption }}</a-descriptions-item>
      <a-descriptions-item label="水压">{{ form.waterGage }}</a-descriptions-item> -->
      <a-descriptions-item label="余额">{{ form.balance }}</a-descriptions-item>
      <a-descriptions-item label="配价方案">{{ form.name }}</a-descriptions-item>
      <a-descriptions-item label="付费模式">
        <a-tag color="blue" v-if="form.payType == 0">平台预付费</a-tag>
        <a-tag color="blue" v-else-if="form.payType == 1">表端预付费</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="配价方案备注">{{ form.pjRemark }}</a-descriptions-item>

      <a-descriptions-item label="用户类型">{{ form.devUserType }}</a-descriptions-item>
      <a-descriptions-item label="绑定人名称">{{ form.userName }}</a-descriptions-item>
      <a-descriptions-item label="绑定人手机号">{{ form.userPhone }}</a-descriptions-item>
      <a-descriptions-item label="绑定人身份证号">{{ form.userIccid }}</a-descriptions-item>

      <a-descriptions-item label="经纬度">{{ form.log }}，{{ form.lat }}</a-descriptions-item>
      <a-descriptions-item label="省市区" :span="2">{{ form.provinceCode }} {{ form.cityCode }} {{ form.areaCode }}</a-descriptions-item>
      <a-descriptions-item label="小区名称">{{ form.community }}</a-descriptions-item>
      <a-descriptions-item label="楼栋">{{ form.building }}</a-descriptions-item>
      <!-- <a-descriptions-item label="单元">{{ form.houseUnit }}</a-descriptions-item>
      <a-descriptions-item label="门牌号">{{ form.houseNumber }}</a-descriptions-item> -->
      <a-descriptions-item label="详细地址" :span="2">{{ form.houseDetail }}</a-descriptions-item>

      <a-descriptions-item label="用量告警">{{ form.dosageWarn }}</a-descriptions-item>
      <a-descriptions-item label="上报周期">{{ form.reportingCycle }}</a-descriptions-item>

      <a-descriptions-item label="最后更新时间">{{ form.endTime }}</a-descriptions-item>
      <!-- <a-descriptions-item label="在线时间">{{ form.onlineTime }}</a-descriptions-item>
      <a-descriptions-item label="离线时间">{{ form.offTime }}</a-descriptions-item>
      <a-descriptions-item label="是否在线">
        <a-tag color="orange" v-if="form.onLine == 2">异常</a-tag>
        <a-tag color="green" v-if="form.onLine == 1">在线</a-tag>
        <a-tag color="red" v-else-if="form.onLine == 0">离线</a-tag>
      </a-descriptions-item> -->

      <a-descriptions-item label="是否有欠费">
        <a-tag color="blue" v-if="form.sfArrearage == 0">否</a-tag>
        <a-tag color="orange" v-else-if="form.sfArrearage == 1">是</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="欠费是否关阀">
        <a-tag color="blue" v-if="form.sfQfValve == 0">否</a-tag>
        <a-tag color="orange" v-else-if="form.sfQfValve == 1">是</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="设备开关阀">
        <a-tag color="orange" v-if="form.status == 0">关</a-tag>
        <a-tag color="blue" v-else-if="form.status == 1">开</a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="添加时间">{{ form.createTime }}</a-descriptions-item>
      <a-descriptions-item label="备注">{{ form.remark }}</a-descriptions-item>
      <!-- <a-descriptions-item label="地图">
        <AmapSearch ref="map" height="300px"/>
      </a-descriptions-item> -->
    </a-descriptions>
  </a-drawer>
</template>

<script>

import { deviceInfo } from '@/api/pages/device'
import AmapSearch from '@/components/AmapSearch'

export default {
  name: 'CreateForm',
   components: { AmapSearch },
  data () {
    return {
      submitLoading: false,
      form: {},
      open: false
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    info (id) {
      deviceInfo({ id }).then(response => {
        this.form = response.data
        this.open = true
        // this.$nextTick(() => {
        //   this.$refs.map.mapInit(response.data.log, response.data.lat)
        // })
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>

<template>
  <a-drawer
    width="500px"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="开始设备编号" prop="startCode">
        <a-input v-model="form.startCode" placeholder="请输入" type="number"/>
      </a-form-model-item>
      <a-form-model-item label="结束设备编号" prop="endCode">
        <a-input v-model="form.endCode" placeholder="请输入" type="number"/>
      </a-form-model-item>
      <a-form-model-item label="设备类型" prop="deviceTypeId">
        <a-select v-model="form.deviceTypeId" placeholder="请选择">
          <a-select-option v-for="(item,key) in typeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="客户" prop="customId">
        <a-select v-model="form.customId" placeholder="请选择">
          <a-select-option v-for="(item,key) in userList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { deviceInfo, deviceAddBatch, deviceTypeList } from '@/api/pages/device'
import { customList } from '@/api/pages/custom'
export default {
  name: 'CreateForm2',
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      typeList: [],
      userList: [],
      // 表单
      form: {
        id: 0,
        startCode: null,
        endCode: null,
        customId: undefined,
        deviceTypeId: undefined
      },
      open: false,
      rules: {
        startCode: [{ required: true, message: '开始设备编号不能为空', trigger: ['blur', 'change'] }, { validator: this.validateNum, trigger: ['blur', 'change'] }],
        endCode: [{ required: true, message: '结束设备编号不能为空', trigger: ['blur', 'change'] }, { validator: this.validateNum, trigger: ['blur', 'change'] }],
        deviceTypeId: [{ required: true, message: '请选择设备类型', trigger: ['blur', 'change'] }],
        customId: [{ required: true, message: '请选择客户', trigger: ['blur', 'change'] }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    validateNum (rule, value, callback) {
      if (!/^(?:[0-9]\d*)$/.test(value)) {
        callback(new Error('请输入正整数'))
      } else if (value.length > 32) {
        callback(new Error('长度不得大于32位'))
      } else {
        callback()
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: 0,
        startCode: null,
        endCode: null,
        customId: undefined,
        deviceTypeId: undefined
      }
      deviceTypeList().then(response => {
        this.typeList = response.data
      })
      customList().then(response => {
        this.userList = response.data
      })
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      setTimeout(() => {
        this.form.typeId = this.typeList[0].id
      }, 500)
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      deviceInfo({ id: configId }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.startCode >= this.form.endCode) {
            this.$message.warning(
              '结束设备编号需大于开始设备编号',
              3
            )
            return false
          }
          this.submitLoading = true
          deviceAddBatch(this.form).then(response => {
            this.$message.success(
              '新增成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
@/api/pages/device
